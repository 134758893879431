import * as Type from "./types";
const SocketReducer = (
  state = {
    socket: {},
    notificationSocket: {},
  },
  action
) => {
  switch (action.type) {
    case Type.SET_SOCKET:
      return (state = {
        ...state,
        socket: action.payload,
      });
    case Type.SET_CONVERSATION_TICKET_SOCKET:
      return (state = {
        ...state,
        ticketSocket: action.payload,
      });
    case Type.SET_NOTIFICATION_SOCKET:
      return (state = {
        ...state,
        notificationSocket: action.payload,
      });
    default:
      return state;
  }
};

export default SocketReducer;
