import * as Type from "./types";
const ConversationTabsReducer = (
  state = {
    socialTab: {
      key: "all",
      value: 0,
    },
    templateTab: {
      key: "text",
      value: 0,
    },
    emailThreadTab: {
      key: "generic",
      value: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case Type.SET_SOCIAL_TAB:
      state = {
        ...state,
        socialTab: action.payload,
      };
      break;
    case Type.SET_TEMPLATE_TAB:
      state = {
        ...state,
        templateTab: action.payload,
      };
      break;
    case Type.SET_EMAIL_THREAD_TAB:
      state = {
        ...state,
        emailThreadTab: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default ConversationTabsReducer;
