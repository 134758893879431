import axios from "axios";
import { serverUrl, authUrl } from "./config";
import { clearLocalStorageItems, getLocalStorageItem } from "./AuthSetting";
import { Routes } from "../utils/Constants";

const instance = axios.create({
  baseURL: serverUrl,
});
instance.interceptors.request.use(
  async function (config) {
    //anything you want to attach to the requests such as token
    if (config.url.startsWith("/auth")) {
      config.baseURL = authUrl;
    } else {
      config.baseURL = serverUrl;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  async function (response) {
    // Do something with response data
    return response;
  },
  async function (error) {
    // Do something with response error

    if (error?.response?.status === 409) {
      const user = getLocalStorageItem("user");
      return instance
        .post(`/auth/force-logout`, {
          id: user?.id,
          isWeb: true,
          uuid: user?.uuid,
        })
        .then((response) => {
          clearLocalStorageItems();
          window.location = Routes.LOGIN;
        });
    }
    return Promise.reject(error);
  }
);
export default instance;
