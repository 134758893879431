const serverUrl =
  process.env.REACT_APP_SERVER_URL || "http://localhost:4000/api";
const authUrl =
  process.env.REACT_APP_AUTH_SERVER_URL ||
  "http://localhost:4005/api/auth-server";
const miscUrl = process.env.REACT_APP_MISC_URL;
const reactAppUrl = process.env.REACT_APP_APP_URL;
const templatesUrl = process.env.REACT_APP_TEMPLATES_SERVER_URL;
const productsUrl = process.env.REACT_APP_PRODUCTS_SERVER_URL;
const appId = process.env.REACT_APP_APP_ID;
const facebookUrl = "https://www.facebook.com/";
export const WebstoreOptions = [
  {
    id: "woocommerce",
    name: "WooCommerce",
  },
  {
    id: "shopify",
    name: "Shopify",
  },
];

export const RoleOptions = [
  { id: "storeManager", name: "Store Manager" },
  { id: "socialMediaManager", name: "Social Media Expert" },
  { id: "admin", name: "Admin" },
  { id: "agent", name: "Agent" },
];
export const ShipperOptions = [
  {
    id: "tcs",
    name: "TCS",
    icon: "https://s3-shuttlepro-bucket.s3.amazonaws.com/logo/tcs.png",
  },
  {
    id: "m&p",
    name: "M&P",
    icon: "https://s3-shuttlepro-bucket.s3.amazonaws.com/logo/mnp.png",
  },
  {
    id: "trax",
    name: "TRAX",
    icon: "https://s3-shuttlepro-bucket.s3.amazonaws.com/logo/trax-logo.svg",
  },
  {
    id: "rider",
    name: "RIDER",
    icon: "https://s3-shuttlepro-bucket.s3.amazonaws.com/logo/rider.png",
  },
  {
    id: "local",
    name: "Local",
    icon: "https://s3-shuttlepro-bucket.s3.amazonaws.com/logo/shuttle.png",
  },
  {
    id: "insta",
    name: "INSTA",
    icon: "https://s3-shuttlepro-bucket.s3.amazonaws.com/logo/insta.png",
  },
  {
    id: "callcourier",
    name: "CALLCOURIER",
    icon: "https://s3-shuttlepro-bucket.s3.amazonaws.com/logo/logo-cc.png",
  },
];

export {
  serverUrl,
  appId,
  facebookUrl,
  miscUrl,
  templatesUrl,
  productsUrl,
  authUrl,
  reactAppUrl,
};
