import * as Type from "./types";
import { isObject } from "lodash";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../config/AuthSetting";
const WorkspaceReducer = (
  state = {
    workspace: getLocalStorageItem("workspace"),
    workspaceId: getLocalStorageItem("workspaceId"),
  },
  action
) => {
  switch (action.type) {
    case Type.SET_WORKSPACE:
      state = {
        ...state,
        workspace: action.payload,
        workspaceId: action.payload?._id || "",
      };
      setLocalStorageItem("workspace", action.payload || null);
      setLocalStorageItem("workspaceId", action.payload?._id || "");
      break;
    default:
      state = {
        workspace: !isObject(state.workspace)
          ? ParseValue(state.workspace)
          : state.workspace,
        workspaceId: state.workspaceId,
      };
      break;
  }
  return state;
};
const ParseValue = (user) => {
  let val = null;
  try {
    val = JSON.parse(user);
  } catch (e) {
    val = null;
  }
  return val;
};
export default WorkspaceReducer;
