import * as Type from "./types";
const PermissionsReducer = (
  state = {
    permissions: null, // Initialize permissions as null
  },
  action
) => {
  switch (action.type) {
    case Type.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      // No need to handle default case if you're not using local storage
      return state;
  }
};

export default PermissionsReducer;
