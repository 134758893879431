import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "react-quill/dist/quill.snow.css";
import "./assets/css/tailwind.output.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./modules"; // Adjust path if necessary
// import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { SidebarProvider } from "./context/SidebarContext";
import { ProgressContextProvider } from "./context/ProgressContext";
import { TailwindThemeProvider } from "./context/ThemeContext";
import ThemedSuspense from "./components/shared/ThemedSuspense";
import * as serviceWorker from "./serviceWorker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./I18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
  typography: {
    fontFamily: "inherit",
  },
});
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <ApiProvider api={conversationApi}> */}
      <TailwindThemeProvider>
        <ThemeProvider theme={theme}>
          <ProgressContextProvider>
            <SidebarProvider value={false}>
              <Suspense fallback={<ThemedSuspense />}>
                <App />
              </Suspense>
            </SidebarProvider>
          </ProgressContextProvider>
        </ThemeProvider>
      </TailwindThemeProvider>
    </PersistGate>
    {/* </ApiProvider> */}
  </Provider>
);

serviceWorker.register();
