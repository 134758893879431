import React, { useState, useMemo, useCallback } from "react";

// create context
export const SidebarContext = React.createContext();

export const SidebarProvider = ({ value = false, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(value);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  }, [setIsSidebarOpen]);

  function closeSidebar() {
    setIsSidebarOpen(false);
  }
  const values = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
    }),
    [isSidebarOpen, toggleSidebar]
  );

  return (
    <SidebarContext.Provider value={values}>{children}</SidebarContext.Provider>
  );
};
