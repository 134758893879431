import {
  SET_ADD_FORM_TYPE,
  SET_ADD_GROUP,
  SET_DYNAMIC_ALL_FIELDS,
  SET_FORM_FIELDS,
  SET_SELECT_GROUP,
} from "./types";

const initialFieldState = {
  allFields: [],
  formFields: { selectedGroupId: "", updatedGroups: [] },
};

const dynamicFieldReducer = (state = initialFieldState, action) => {
  switch (action.type) {
    case SET_DYNAMIC_ALL_FIELDS:
      return {
        formFields: {
          selectedGroupId: "basinInfo",
          updatedGroups: action.payload,
        },
        allFields: action.payload || [],
      };

    case SET_SELECT_GROUP:
      return {
        ...state,
        formFields: { ...state?.formFields, selectedGroupId: action.payload },
      };

    case SET_FORM_FIELDS:
      return {
        ...state,
        formFields: {
          ...state?.formFields,
          updatedGroups: action.payload || [],
        },
      };
    case SET_ADD_FORM_TYPE:
      return {
        ...state,
        formFields: {
          ...state?.formFields,
          updatedGroups: action.payload || [],
        },
      };

    case SET_ADD_GROUP:
      return {
        ...state,
        formFields: action.payload,
      };

    default:
      return state;
  }
};

export default dynamicFieldReducer;
