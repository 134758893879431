import * as Type from "./types";
const EmailReplyReducer = (
	state = {
		reply: false,
	},
	action
) => {
	switch (action.type) {
		case Type.SET_REPLY:
			state = {
				...state,
				reply: action.payload,
			};
			break;

		default:
			break;
	}
	return state;
};

export default EmailReplyReducer;
