import messages from "./messages.json";
import validation from "./validation.json";
import common from "./common.json";
import labels from "./labels.json";
export const en = {
  translation: {
    ...messages,
    ...common,
    ...validation,
    ...labels,
  },
};
