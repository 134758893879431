import React, { useContext } from "react";
import { css } from "@emotion/react";
import { ThemeContext } from "../../../context/ThemeContext";
import ScaleLoader from "react-spinners/ScaleLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spinner = ({ loading, size = 24, color, className }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <ScaleLoader
      className={`inline-block ${className}`}
      color={color || (theme === "light" ? "#333" : "#fafafe")}
      loading={loading || true}
      css={override}
      size={size || 32}
      height={size}
    />
  );
};

export default Spinner;
