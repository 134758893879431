import instance from "../../../config/axios";
import {
  ParseError,
  getAuthHeader,
  getWorkspaceId,
} from "../../../config/utils";
import { isObject } from "lodash";
const responseData = {
  loading: false,
  status: 210,
  message: "something.went.wrong",
};

const CreateColumn = async (title, type) => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();

  return instance
    .post(
      "taskManager/columns",
      {
        title: title,
        type: type,
        workspaceId: workspaceId,
      },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const CreateComment = async (body) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .post(`taskManager/comments?workspaceId=${workspaceId}`, body, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const EditColumn = async (id, title, type = "") => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .put(
      `taskManager/columns/${id}?workspaceId=${workspaceId}`,
      { title: title, type: type },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const DeleteColumn = async (id) => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .delete(`taskManager/columns/${id}?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const CreateType = async (data) => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();

  return instance
    .post(
      "taskManager/types",
      {
        ...data,
        workspaceId: workspaceId,
      },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const UpdateTypeById = async (formValues) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(
      `taskManager/updateType/${formValues?.id}?workspaceId=${workspaceId}`,
      formValues,
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};
const FetchTypes = async () => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(`taskManager/types?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const FetchColumnCards = async (columnId, page, limit, search) => {
  try {
    const { headers } = await getAuthHeader();
    const workspaceId = await getWorkspaceId();
    const response = await instance.get("taskManager/fetchCardsByColumnId", {
      headers,
      params: {
        columnId,
        page,
        limit,
        search,
        workspaceId,
      },
    });
    if (response.data.code !== 200) {
      throw response;
    } else {
      return response.data;
    }
  } catch (err) {
    throw new Error(ParseError(err?.response?.data || err?.data || err));
  }
};

const FetchAllColumns = async () => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(`taskManager/allColumns?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const EditComment = async (id, editedText) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(
      `taskManager/comment/${id}`,
      { commentText: editedText, workspaceId: workspaceId },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const DeleteComment = async (id) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .delete(`taskManager/comment/${id}?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const DeleteCard = async (id) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .delete(`taskManager/card/${id}?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const MarkCommentsAsRead = async (id, userId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(
      `taskManager/card/markAsRead/${id}`,
      { userId: userId, workspaceId: workspaceId },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const DeleteType = async (id) => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .delete(`taskManager/types/${id}?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const CreateCard = async (body) => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();

  return instance
    .post(`taskManager/card?workspaceId=${workspaceId}`, body, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const GetCardById = async (cardId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .get(`/taskManager/cardById/${cardId}?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};
const UpdateCard = async (body, cardId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(`taskManager/card/${cardId}?workspaceId=${workspaceId}`, body, token)
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};
const UpdateCardImages = async (s3Links, cardId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(
      `taskManager/card-images/${cardId}?workspaceId=${workspaceId}`,
      { s3Links: s3Links },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};
const UpdateCardViewBy = async (cardId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(
      `taskManager/card-view/${cardId}?workspaceId=${workspaceId}`,
      {},
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};
const deleteCardImage = async (s3Link, cardId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(
      `taskManager/delete-card-image/${cardId}?workspaceId=${workspaceId}`,
      { attachmentId: s3Link },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};

const UpdateCardsPosition = async (cardId, columnId, compareActivities) => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `taskManager/updateCardsPosition/${cardId}?workspaceId=${workspaceId}`,
      {
        columnId: columnId,
        workspaceId: workspaceId,
        compareActivities,
      },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};
const UpdateColumnsPosition = async (columns) => {
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `taskManager/updateColumnsPosition/?workspaceId=${workspaceId}`,
      { columns },
      token
    )
    .then((response) => {
      if (response?.data?.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isObject(response.data) ? response.data : {},
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err?.response?.data ? err.response.data : err?.message
        ),
      };
    });
};
const SearchStatuses = async (query = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(
      `/taskManager/searchStatus?workspaceId=${workspaceId}&query=${query}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const SearchTypes = async (query = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(
      `/taskManager/searchType?workspaceId=${workspaceId}&query=${query}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchTaskManagerCards = async (
  workspaceId,
  search,
  category = "ticket",
  integrationId = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(
      `/taskManager/getCards?workspaceId=${workspaceId}&search=${search}&category=${category}&integrationId=${integrationId}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            data: data,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchDynamicField = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(`/taskManager/dynamicField?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            data: data,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const CreateDynamicField = async (body = {}) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(`/taskManager/dynamicField?workspaceId=${workspaceId}`, body, token)
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            data: data,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UpdateDynamicField = async (body = {}) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .put(`/taskManager/dynamicField?workspaceId=${workspaceId}`, body, token)
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            data: data,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const DeleteDynamicField = async (fieldId = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  if (!fieldId) {
    return responseData;
  }
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .delete(
      `/taskManager/dynamicField/${fieldId}?workspaceId=${workspaceId}`,
      token
    )
    .then((response) => {
      if (response.status === 200) {
        response = response.data;
        if (response.code === 200) {
          const data = response.data;
          return {
            ...responseData,
            status: 200,
            data: data,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export {
  CreateColumn,
  EditColumn,
  DeleteColumn,
  CreateType,
  FetchTypes,
  DeleteType,
  CreateCard,
  UpdateCardsPosition,
  UpdateColumnsPosition,
  UpdateCard,
  CreateComment,
  EditComment,
  DeleteComment,
  MarkCommentsAsRead,
  DeleteCard,
  GetCardById,
  FetchColumnCards,
  FetchAllColumns,
  SearchStatuses,
  SearchTypes,
  UpdateTypeById,
  FetchTaskManagerCards,
  CreateDynamicField,
  FetchDynamicField,
  UpdateDynamicField,
  DeleteDynamicField,
  UpdateCardImages,
  deleteCardImage,
  UpdateCardViewBy,
};
