import cryptoJS from "crypto-js";
const Securitykey = process.env.REACT_APP_ENCRYPTER;

const setLocalStorageItem = (key, data) => {
  const encrypted = cryptoJS.AES.encrypt(
    JSON.stringify(data || ""),
    Securitykey
  ).toString();
  try {
    localStorage.setItem(key, encrypted);
  } catch (e) {
    if (e instanceof DOMException && e.name === "QuotaExceededError") {
      // Handle the quota exceeded error here.
      // This could involve clearing old data or showing a user-friendly message.
    } else {
      // Handle other localStorage-related errors.
    }
  }
};
const getLocalStorageItem = (key) => {
  const encrypted = localStorage.getItem(key);
  if (!encrypted) return null;
  const decrypted = cryptoJS.AES.decrypt(encrypted, Securitykey).toString(
    cryptoJS.enc.Utf8
  );
  // console.log("decrypted", decrypted);
  return JSON.parse(decrypted);
};

const clearLocalStorageItems = () => {
  localStorage.clear();
};

export { setLocalStorageItem, getLocalStorageItem, clearLocalStorageItems };
