import * as Type from "./types";

const setActiveTabReducer = (state = { orderActiveTab: "pending" }, action) => {
  switch (action.type) {
    case Type.SET_ACTIVE_TAB:
      state = { orderActiveTab: action.payload };
      break;
    default:
      break;
  }
  return state;
};
export default setActiveTabReducer;
