import * as Type from "./types";

const CartReducer = (state, action) => {
  switch (action.type) {
    case Type.SET_CART:
      if (state.cart.length === 0) {
        state = {
          ...state,
          cart: [...state.cart, { ...action.payload, orderQuantity: 1 }],
        };
        break;
      } else {
        const existedItem = state.cart.find(
          (product) => product.id === action.payload.id
        );
        if (existedItem) {
          const newCart = state.cart.map((product) => {
            if (product.id === action.payload.id) {
              const updatedQuantity = product.orderQuantity + 1;
              return { ...product, orderQuantity: updatedQuantity };
            } else {
              return { ...product };
            }
          });
          state = {
            ...state,
            cart: newCart,
          };
        } else {
          state = {
            ...state,
            cart: [...state.cart, { ...action.payload, orderQuantity: 1 }],
          };
        }
      }
      break;
    case Type.REMOVE_CART:
      if (action.payload.orderQuantity === 1) {
        // break;
        const updatedCart = state.cart.filter(
          (product) => product.id !== action.payload.id
        );
        state = {
          ...state,
          cart: updatedCart,
        };
        break;
      } else {
        const newCart = state.cart.map((product) => {
          if (product.id === action.payload.id) {
            const updatedQuantity = product.orderQuantity - 1;
            return { ...product, orderQuantity: updatedQuantity };
          } else {
            return { ...product };
          }
        });
        state = {
          ...state,
          cart: newCart,
        };
      }

      break;
    case Type.DELETE_CART:
      state = {
        ...state,
        cart: [],
      };
      break;
    default:
      state = {
        cart: [],
      };
      break;
  }
  return state;
};

export default CartReducer;
