import * as Types from "./types";

export const SetCurrentProfile = (data) => ({
  type: Types.SET_CURRENT_PROFILE,
  payload: data,
});

export const SetCreatePostType = (data) => ({
  type: Types.SET_CREATE_POST_TYPE,
  payload: data,
});

export const SetPostModals = (data) => ({
  type: Types.SET_POST_MODAL,
  payload: data,
});

export const SetPostType = (data) => ({
  type: Types.SET_POST_TYPE,
  payload: data,
});

export const SetSelected = (data) => ({
  type: Types.SET_SELECTED,
  payload: data,
});

export const SetProductIds = (data) => ({
  type: Types.SET_PRODUCT_IDS,
  payload: data,
});

export const SetTagId = (data) => ({
  type: Types.SET_TAG_ID,
  payload: data,
});

export const SetOpenPostModal = (data) => ({
  type: Types.SET_OPEN_POST_MODAL,
  payload: data,
});

export const SetSocialProfileId = (data) => ({
  type: Types.SET_SOCIAL_PROFILE_ID,
  payload: data,
});

export const SetTemplateId = (data) => ({
  type: Types.SET_TEMPLATE_ID,
  payload: data,
});

export const SetDescriptionTemplateId = (data) => ({
  type: Types.SET_DESCRIPTION_TEMPLATE_ID,
  payload: data,
});
