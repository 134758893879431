import * as Type from "./types";

const initialState = null;
const ConversationOrderReducer = (
  state = null,
  action
) => {
  switch (action.type) {
    case Type.SET_CONVERSATION_ORDER:
        state =  action.payload;
        break;
    case Type.RESET_CONVERSATION_ORDER:
        state = initialState;
        break;
    default:
        break;
  }
  return state;
};

export default ConversationOrderReducer;
