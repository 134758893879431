import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner";
import Text from "../Text";
import { SPINNER_SIZE } from "../../../utils/Constants";

const ThemedSuspense = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-screen p-6 flex items-center flex-col justify-center text-lg font-medium bg-primary">
      <Spinner size={SPINNER_SIZE.LARGER} />
      <Text className="text-fontSecondary">{t("please.wait")}</Text>
    </div>
  );
};

export default ThemedSuspense;
