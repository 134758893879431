import { createContext, useMemo, useState } from "react";

export const ProgressContext = createContext();

// create context provider
export const ProgressContextProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);

  const values = useMemo(
    () => ({ progress, setProgress }),
    [progress, setProgress]
  );

  return (
    <ProgressContext.Provider value={values}>
      {children}
    </ProgressContext.Provider>
  );
};
