import * as Type from "./types";
import { isObject } from "lodash";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "../../config/AuthSetting";
const UserReducer = (
  state = {
    user: getLocalStorageItem("user"),
    userId: "",
  },
  action
) => {
  switch (action.type) {
    case Type.SET_USER:
      state = {
        ...state,
        user: action.payload,
        userId: (action.payload && action.payload.id) || "",
      };
      setLocalStorageItem("user", action.payload ? action.payload : null);
      break;
    default:
      const u = !isObject(state.user) ? ParseValue(state.user) : state.user;
      state = {
        user: u,
        userId: state.userId,
      };
      break;
  }
  return state;
};
const ParseValue = (user) => {
  let val = null;
  try {
    val = JSON.parse(user);
  } catch (e) {
    val = null;
  }
  return val;
};
export default UserReducer;
