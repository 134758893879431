import instance from "../../../config/axios";
import {
  ParseError,
  getAuthHeader,
  getWorkspaceId,
} from "../../../config/utils";
import { getLocalStorageItem } from "../../../config/AuthSetting";
const FetchDetaultRolesPermissions = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/permissions/default`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const FetchUsersPermissions = async (page = 1, limit = 100000) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const loggedUser = getLocalStorageItem("user");
  const workspace = getLocalStorageItem("workspace");

  const token = await getAuthHeader();
  return instance
    .get(
      `/users/permission?workspaceId=${workspaceId}&page=${page}&limit=${limit}&userId=${loggedUser.id}&workspaceUser=${workspace.user_id}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            count: response.count,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchUserPermissions = async (workspaceId) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(`/permissions/user-permissions/?workspaceId=${workspaceId}`, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchingUserAllPermissions = async (workspaceId, userId) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const token = await getAuthHeader();
  return instance
    .get(
      `/permissions/member-permissions?workspaceId=${workspaceId}&id=${userId}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const UpdateUserRolePermissions = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .put(`/permissions?workspaceId=${workspaceId}`, data, token)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            count: response.count,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const UpdateUserRole = async (userId, role) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .put(
      `/permissions/role?workspaceId=${workspaceId}&userId=${userId}&role=${role}`,
      {},
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;
        if (response.code === 200) {
          return {
            ...responseData,
            status: 200,
            data: response.data,
            count: response.count,
            message: response.message,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
// const FetchMailGroupsByQuery = async (page = 1, limit = 100000, query = "") => {
//   const responseData = {
//     loading: false,
//     status: 210,
//     message: "Something went wrong, Please try again.",
//   };
//   const workspaceId = await getWorkspaceId();
//   const token = await getAuthHeader();
//   return instance
//     .get(
//       `/mailGroups/search?workspaceId=${workspaceId}&page=${page}&limit=${limit}&query=${query}`,
//       token
//     )
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         response = response.data;
//         if (response.code === 200) {
//           return {
//             ...responseData,
//             status: 200,
//             data: response.data,
//             count: response.count,
//           };
//         } else {
//           return {
//             ...responseData,
//             message: response.message,
//           };
//         }
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
// };

// const CreateMailGroup = async (formData) => {
//   const responseData = {
//     loading: false,
//     status: 210,
//     message: "Something went wrong, Please try again.",
//   };
//   const workspaceId = await getWorkspaceId();
//   const token = await getAuthHeader();
//   return instance
//     .post(`/mailGroups`, { ...formData, workspaceId: workspaceId }, token)
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         response = response.data;
//         if (response.code === 200) {
//           return {
//             ...responseData,
//             status: 200,
//             data: response.data,
//             message: response.message,
//           };
//         } else {
//           return {
//             ...responseData,
//             message: response.message,
//           };
//         }
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
// };

// const UpdateMailGroup = async (formData, id) => {
//   const responseData = {
//     loading: false,
//     status: 210,
//     message: "Something went wrong, Please try again.",
//   };
//   const workspaceId = await getWorkspaceId();
//   const token = await getAuthHeader();
//   return instance
//     .put(`/mailGroups/${id}`, { ...formData, workspaceId: workspaceId }, token)
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         response = response.data;
//         if (response.code === 200) {
//           return {
//             ...responseData,
//             status: 200,
//             data: response.data,
//             message: response.message,
//           };
//         } else {
//           return {
//             ...responseData,
//             message: response.message,
//           };
//         }
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
// };

// const DeleteMailGroup = async (id) => {
//   const responseData = {
//     loading: false,
//     status: 210,
//     message: "Something went wrong, Please try again.",
//   };
//   const token = await getAuthHeader();
//   return instance
//     .delete(`/mailGroups/${id}`, token)
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         response = response.data;
//         if (response.code === 200) {
//           return {
//             ...responseData,
//             status: 200,
//             data: response.data,
//             message: response.message,
//           };
//         } else {
//           return {
//             ...responseData,
//             message: response.message,
//           };
//         }
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
// };

export {
  FetchDetaultRolesPermissions,
  FetchUsersPermissions,
  UpdateUserRolePermissions,
  FetchUserPermissions,
  FetchingUserAllPermissions,
  UpdateUserRole,
};
