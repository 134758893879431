import * as Type from "./types";

const initialState = {
  currentProfile: null,
  postModal: "",
  postType: {
    postType: false,
    postTypeValue: "",
  },
  selected: "photo",
  productIds: [],
  tagId: null,
  openPostModal: false,
  socialProfileId: "",
  templateId: "",
  descriptionTemplateId: "",
  createPostType: "custom",
};

const PostReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_CURRENT_PROFILE:
      return {
        ...state,
        currentProfile: action.payload,
      };
    case Type.SET_POST_MODAL:
      return {
        ...state,
        postModal: action.payload,
      };
    case Type.SET_CREATE_POST_TYPE:
      return {
        ...state,
        createPostType: action.payload,
      };
    case Type.SET_POST_TYPE:
      return {
        ...state,
        postType: action.payload,
      };
    case Type.SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case Type.SET_PRODUCT_IDS:
      return {
        ...state,
        productIds: action.payload,
      };
    case Type.SET_TAG_ID:
      return {
        ...state,
        tagId: action.payload,
      };
    case Type.SET_OPEN_POST_MODAL:
      return {
        ...state,
        openPostModal: action.payload,
      };
    case Type.SET_SOCIAL_PROFILE_ID:
      return {
        ...state,
        socialProfileId: action.payload,
      };
    case Type.SET_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload,
      };
    case Type.SET_DESCRIPTION_TEMPLATE_ID:
      return {
        ...state,
        descriptionTemplateId: action.payload,
      };
    default:
      return state;
  }
};

export default PostReducer;
