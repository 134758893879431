export const SET_ACTIVE_CONTACT = "SET_ACTIVE_CONTACT";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_HANDLE_REFRESH = "SET_HANDLE_REFRESH";
export const SET_SENDER_INFO = "SET_SENDER_INFO";
export const SET_PROFILE_PIC = "SET_PROFILE_PIC";
export const SET_QUICK_REPLY = "SET_QUICK_REPLY";
export const SET_INTERNAL_INPUT = " SET_INTERNAL_INPUT";
export const UPDATE_QUICK_REPLY_FORMAT = "UPDATE_QUICK_REPLY_FORMAT";
export const SET_ATTACHMENT_INPUT = "SET_ATTACHMENT_INPUT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_SELECTED_ATTACHMENT = "SET_SELECTED_ATTACHMENT";
export const SET_TAB = "SET_TAB";
export const SET_ACTIVITY_ID = "SET_ACTIVITY_ID";
export const SET_REDIRECTED_ID = "SET_REDIRECTED_ID";
export const SET_DYNAMIC_FEILDS = "SET_DYNAMIC_FEILDS";
