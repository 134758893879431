import * as Type from "./types";

const AddCardModalReducer = (state = false, action) => {
  switch (action.type) {
    case Type.SET_ADD_CARD_MODAL:
      state = action.payload;
      break;
    default:
      break;
  }
  return state;
};

export default AddCardModalReducer;
