export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE";
export const SET_POST_MODAL = "SET_POST_MODAL";
export const SET_POST_TYPE = "SET_POST_TYPE";
export const SET_SELECTED = "SET_SELECTED";
export const SET_PRODUCT_IDS = "SET_PRODUCT_IDS";
export const SET_TAG_ID = "SET_TAG_ID";
export const SET_OPEN_POST_MODAL = "SET_OPEN_POST_MODAL";
export const SET_SOCIAL_PROFILE_ID = "SET_SOCIAL_PROFILE_ID";
export const SET_TEMPLATE_ID = "SET_TEMPLATE_ID";
export const SET_DESCRIPTION_TEMPLATE_ID = "SET_DESCRIPTION_TEMPLATE_ID";
export const SET_CREATE_POST_TYPE = "SET_CREATE_POST_TYPE";
