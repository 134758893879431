import * as Type from "./types";
const SocialProfileReducer = (state, action) => {
  switch (action.type) {
    case Type.SET_SOCIAL_PROFILE_MODAL:
      state = {
        ...state,
        socialProfileModal: action.payload.socialProfileModal,
      };
      break;
    default:
      state = {
        socialProfileModal: false,
      };
      break;
  }
  return state;
};

export default SocialProfileReducer;
