import * as Type from "./types";

const initialState = {
  workspace: null,
  userPermissions: [],
  integrations: [],
  members: [],
};

const UserWorkspaceSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_USER_WORKSPACE_SETTINGS:
      return {
        ...state,
        workspace: action.payload?.workspace || null,
        userPermissions: action.payload?.userPermissions || [],
        integrations: action.payload?.integrations || [],
        members: action.payload?.members || [],
      };
    default:
      return state;
  }
};

export default UserWorkspaceSettingsReducer;
