import * as Type from "./types";
const ConversationMediaReducer = (
  state = {
    mediaAttachment: [],
  },
  action
) => {
  switch (action.type) {
    case Type.SET_MEDIA_ARR:
      state = {
        ...state,
        mediaAttachment: action.payload,
      };
      break;

    case Type.FILTER_MEDIA_BY_ID:
      state = {
        ...state,
        mediaAttachment:
          state.mediaAttachment.length > 0 &&
          state.mediaAttachment.filter((f) => f.id !== action.payload),
      };
      break;

    default:
      break;
  }
  return state;
};

export default ConversationMediaReducer;
