import { isArray, isString } from "lodash";
import { getLocalStorageItem } from "./AuthSetting";
export const getAuthHeader = (value) => {
  return new Promise((resolve) => {
    const token = getLocalStorageItem("token");
    if (token) {
      resolve({
        headers: value
          ? {
              "content-disposition": value,
              authorization: "Bearer " + token,
            }
          : {
              authorization: "Bearer " + token,
            },
      });
    } else resolve(null);
  });
};

export const getWorkspaceId = () => {
  return new Promise((resolve) => {
    // let workspaceId = getLocalStorageItem("workspaceId");
    // if (!workspaceId) {
    let workspaceId = null;
    const url = new URL(window?.location?.href);
    const pathname = url?.pathname;
    const pathParts = pathname?.split("/");
    const workspaceIdIndex = pathParts?.indexOf("workspaces") + 1;
    workspaceId = pathParts[workspaceIdIndex];
    // }
    return resolve(workspaceId || "");
  });
};
export const gettingEmail = () => {
  return new Promise((resolve) => {
    const email = getLocalStorageItem("email");

    return resolve(email);
  });
};

export const getWorkspaceName = () => {
  const { name } = getLocalStorageItem("workspace");
  return name || "";
};
export const ParseError = (error) => {
  let err = "Something went wrong, Please try again.";
  if (error.message && isArray(error.message)) {
    err = error.message[0];
    if (!isString(err) && isArray(err)) {
      err = err[0] && err[0].msg ? err[0].msg : err[0];
    } else {
      err = err.msg;
    }
  } else {
    if (error.message && isString(error.message)) {
      err = error.message;
    }
    if (error.message && isString(error.message)) {
      err = error.message;
    }
  }
  if (err === "Invalid login credentials. Please try again.") {
    err = "Email and password is invalid!";
  }
  return err;
};
