import {
  SET_ADD_FORM_TYPE,
  SET_ADD_GROUP,
  SET_DYNAMIC_ALL_FIELDS,
  SET_FORM_FIELDS,
  SET_SELECT_GROUP,
} from "./types";

export const setAllDynamicFields = (allFields) => ({
  type: SET_DYNAMIC_ALL_FIELDS,
  payload: allFields,
});
export const setSelectGroup = (groupId) => ({
  type: SET_SELECT_GROUP,
  payload: groupId,
});
export const setFormFields = (allFields) => ({
  type: SET_FORM_FIELDS,
  payload: allFields,
});
export const setAddFormType = (allFields) => ({
  type: SET_ADD_FORM_TYPE,
  payload: allFields,
});
export const setAddGroup = (allFields) => ({
  type: SET_ADD_GROUP,
  payload: allFields,
});
