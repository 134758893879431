import * as Type from "./types";
const EmailTemplateReducer = (
  state = {
    template: {},
  },
  action
) => {
  switch (action.type) {
    case Type.SET_TEMPLATE:
      state = {
        ...state,
        template: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
};

export default EmailTemplateReducer;
