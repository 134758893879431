import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage"; // Default localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import { WorkspaceReducer } from "./Workspace";
import { PostReducer } from "./Post";
import UserReducer from "./User/reducer";
import { PermissionsReducer } from "./Permissions";
import { SocialProfileReducer } from "./SocialProfile";
import { CartReducer } from "./Ecommerce";
import { ConversationOrderReducer } from "./ConversationOrder";
import { AddCardModalReducer } from "./TaskManager";
import { ConversationTabsReducer } from "./ConversationTabs";
import { ConversationActiveContactReducer } from "./ConversationActiveContact";
import { AttachmentReducer } from "./ConversationAttachments";
import { ConversationMediaReducer } from "./ConversationMediaAttachments";
import { ConversationLabelsReducer } from "./ConversationLabels";
import { SocketReducer } from "./Socket";
import { EmailTemplateReducer } from "./ConversationEmailTemplate";
import { ActiveStateReducer } from "./Order";
import { EmailReplyReducer } from "./ConversationEmailReply";
import { crmSettingReducer } from "./CRM";
import { MediaReducer } from "./ConversationMedia";
import { LabelReducer } from "./Labels";
import { ConversationProfileReducer } from "./ConversationProfile";
import { ConversationListReducer } from "./ConversationList";
import { ConversationFilterReducer } from "./ConversationFilter";
import { ConversationLabelReducer } from "./ConversationLabel";
import { dynamicFieldReducer } from "./DynamicFields";
import { UserWorkspaceSettingsReducer } from "./UserWorkspaceSettings";

const userWorkspaceSettingsPersistConfig = {
  key: "userWorkspaceSettings",
  storage,
};

// import logger from "redux-logger";
// import promise from "redux-promise-middleware";
const appReducer = combineReducers({
  /* your app’s top-level reducers */
  workspace: WorkspaceReducer,
  user: UserReducer,
  permissions: PermissionsReducer,
  socialProfileModal: SocialProfileReducer,
  cart: CartReducer,
  post: PostReducer,
  conversationOrder: ConversationOrderReducer,
  profile: ConversationProfileReducer,
  addCardModal: AddCardModalReducer,
  conversationTab: ConversationTabsReducer,
  conversationActiveContact: ConversationActiveContactReducer,
  conversationFilter: ConversationFilterReducer,
  conversationLabel: ConversationLabelReducer,
  conversationAttachment: AttachmentReducer,
  conversationMedia: MediaReducer,
  conversationMediaAttachmnet: ConversationMediaReducer,
  conversationLabels: ConversationLabelsReducer,
  socket: SocketReducer,
  conversationEmailTemplate: EmailTemplateReducer,
  order: ActiveStateReducer,
  reply: EmailReplyReducer,
  crmSetting: crmSettingReducer,
  labelReducer: LabelReducer,
  conversationReducer: ConversationListReducer,
  dynamicFields: dynamicFieldReducer,
  userWorkspaceSettings: persistReducer(
    userWorkspaceSettingsPersistConfig,
    UserWorkspaceSettingsReducer
  ),
});

const middlewares = [thunk];

// Create the store and apply persistence
export const store = createStore(appReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);
