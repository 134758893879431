import messages from "./messages.json";
import validation from "./validation.json";
import common from "./common.json";
export const fr = {
  translation: {
    ...messages,
    ...common,
    ...validation,
  },
};
